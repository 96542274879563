import { Box, Typography } from "@mui/material";
import styles from "./styles.module.scss";

export const Hero = (props: any) => {
  return (
    <Box className={styles.hero} {...props}>
      <img src="./hero.jpg" alt="hero" className={styles.hero__media} />
      <div className="hero__overlay" />

      <Box className={styles.hero__content}>
        <Typography className="transparent__text">Empowering</Typography>
        <Typography variant="h1">Empowering World’s Trade</Typography>
        <Typography variant="h5">
          More than one service helps you to grow your business in the right way
        </Typography>
      </Box>
    </Box>
  );
};
