import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as LogoWhite } from "../../assets/white-logo.svg";
import { ReactComponent as LogoBlack } from "../../assets/black-logo.svg";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import { Link as ScrollLink } from "react-scroll";
import { useBlogs } from "hooks/useBlogs";
import { useHighlights } from "hooks/useHighlights";

export const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [whiteAppBar, setWhiteAppBar] = useState(false);
  const [hasLinks, setHasLinks] = useState(true);
  const { pathname } = useLocation();
  const { blogs } = useBlogs();
  const { highlights } = useHighlights();
  const sections = ["Home", "What is OTL ?", "Otrovato"];

  if (blogs?.results && blogs?.results.length > 0) sections.push("Blogs");
  if (highlights?.results && highlights?.results.length > 0)
    sections.push("Highlights");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  useEffect(() => {
    setWhiteAppBar(pathname === "/blogs" || scrollPosition > 100);
    setHasLinks(pathname === "/home");
  }, [scrollPosition, pathname]);

  useEffect(() => {
    window.onload = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const appBarStyle = {
    backgroundColor: whiteAppBar ? "white" : "transparent",
    transition: "background-color 0.3s ease-in-out",
  };
  const menuIconStyle = {
    color: whiteAppBar ? "black" : "white",
  };

  return (
    <AppBar color="inherit" elevation={0} style={appBarStyle}>
      <Container maxWidth="lg">
        <Toolbar
          sx={{
            width: "100%",
            paddingBlock: 3,
            paddingInline: "0px !important",
          }}
          variant="dense"
        >
          <Box
            sx={{ display: { xs: "none", md: "flex" }, mr: hasLinks ? 15 : 0 }}
          >
            <Link to="/">{whiteAppBar ? <LogoBlack /> : <LogoWhite />}</Link>
          </Box>

          {hasLinks && (
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {sections.map((section, idx) => (
                <ScrollLink
                  className="scroll__link"
                  style={{ color: whiteAppBar ? "black" : "white" }}
                  key={`section-#-${idx}`}
                  to={section}
                  spy={true}
                  smooth={true}
                  duration={1000}
                  offset={-85}
                >
                  <Typography variant="body1">{section}</Typography>
                </ScrollLink>
              ))}
            </Box>
          )}

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, mr: 1 }}>
            <Link to="/">{whiteAppBar ? <LogoBlack /> : <LogoWhite />}</Link>
          </Box>

          {hasLinks && (
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon style={menuIconStyle} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
                MenuListProps={{ sx: { padding: "1rem" } }}
              >
                {sections.map((section, idx) => (
                  <ScrollLink
                    className="scroll__link"
                    style={{
                      color: whiteAppBar ? "black" : "white",
                      display: "block",
                      paddingBlock: "8px",
                    }}
                    key={`section-#-${idx}`}
                    to={section}
                    spy={true}
                    smooth={true}
                    duration={1000}
                    offset={-96}
                    onClick={() => handleCloseNavMenu()}
                  >
                    <Typography variant="body1" sx={{ color: "black" }}>
                      {section}
                    </Typography>
                  </ScrollLink>
                ))}
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
