import { Box, Container, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { BlogResult } from "dtos/get-blogs.dto";
import moment from "moment";
import { useBreadcrumbs } from "contexts/breadcrumbContext";
import { useEffect } from "react";
import { Breadcrumb } from "components/core/breadcrumb";

type Props = {
  blog: BlogResult;
};

export const BlogHero = ({ blog, ...props }: Props) => {
  const publishedAt = moment(blog.publishedAt).format("MMMM DD, YYYY");
  const { updateBreadcrumbs, breadcrumbItems } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([
      { label: "Blogs", link: "/blogs" },
      { label: "Blog Details", link: "" },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {blog && blog.values && (
        <Box className={styles.hero} {...props}>
          {blog.values.image && (
            <img
              className={styles.hero__image}
              src={blog.values?.image.url}
              alt={blog.values?.image.altText}
            />
          )}
          <div className="hero__overlay" />

          <Box
            sx={{
              position: "absolute",
              top: "100px",
              marginBottom: 2,
              width: "100%",
            }}
          >
            <Container maxWidth="lg">
              <Breadcrumb
                breadcrumbs={breadcrumbItems}
                color="var(--lightGray)"
                activeColor="white"
              />
            </Container>
          </Box>

          <Container maxWidth="lg" className={styles.hero__content}>
            <Typography variant="h2">{blog.values.title}</Typography>
            <Typography variant="h4">{blog.values.description}</Typography>
            <Box className={styles.hero__footer}>
              <img
                id="author-image"
                src={blog.values?.author_image?.url}
                alt={blog.values?.author_image?.altText}
              />
              <Typography
                variant="body2"
                id="author"
                className={styles.hero__footer__author}
              >
                {blog.values?.author}
              </Typography>
              <Typography variant="body2" id="published-at">
                {publishedAt}
              </Typography>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};
