import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.scss";
import { Layout } from "components/layout";
import { HomePage } from "pages/home";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { theme } from "utils/Themes";
import { BlogsPage } from "pages/blogs/blog-listing";
import { BlogInnerPage } from "pages/blogs/blog-inner";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { useEffect } from "react";

const router = createBrowserRouter([
  { path: "/", element: <Navigate to="/home" /> },
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/home", element: <HomePage /> },
      { path: "/blogs", element: <BlogsPage /> },
      { path: "/blog/:id", element: <BlogInnerPage /> },
    ],
  },
]);

export const App = () => {
  const initializeTagManager = () => {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID || "",
    };
    TagManager.initialize(tagManagerArgs);
  };

  useEffect(() => {
    initializeTagManager();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
