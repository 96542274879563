import { createTheme } from "@mui/material";
import { Colors } from "./colors";

const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

export const theme = createTheme({
  direction: "ltr",
  typography: {
    fontFamily: "Poppins, Lato",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: 700,
          fontSize: "80px",
          [breakpoints.down("md")]: {
            fontSize: "46px",
          },
        },
        h2: {
          fontWeight: 700,
          [breakpoints.down("md")]: {
            fontSize: "20px",
          },
        },
        h3: {
          fontWeight: 700,
          [breakpoints.down("md")]: {
            fontSize: "20px",
          },
        },
        h4: {
          [breakpoints.down("md")]: {
            fontSize: "14px",
          },
        },
        button: {
          textTransform: "capitalize",
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Lato",
          fontWeight: 600,
          textTransform: "capitalize",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 500,
          [breakpoints.down("md")]: {
            fontSize: "8px",
          },
        },
        colorPrimary: {
          backgroundColor: "#03705A0D",
          color: Colors.green,
        },
      },
    },
  },
  palette: {
    primary: {
      main: Colors.green,
      "100": Colors.lightGreen,
    },
    secondary: {
      main: Colors.lightOrange,
      "100": Colors.orange,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.red,
    },
    grey: {
      "100": Colors.midGray,
      "200": Colors.lightGray,
    },
  },
});
