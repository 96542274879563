import { ReactComponent as ArrowRight } from "assets/arrow-right.svg";

export const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowRight />
    </div>
  );
};
