import { Box, Container, Typography } from "@mui/material";
import { BlogGrid } from "components/core/blog-grid";
import { Breadcrumb } from "components/core/breadcrumb";
import { Loader } from "components/core/loader";
import { useBreadcrumbs } from "contexts/breadcrumbContext";
import { useBlogs } from "hooks/useBlogs";
import { useEffect } from "react";

export const BlogsPage = () => {
  const { blogs } = useBlogs();
  const { updateBreadcrumbs, breadcrumbItems } = useBreadcrumbs();

  useEffect(() => {
    updateBreadcrumbs([{ label: "Blogs", link: "/blogs" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (blogs.total <= 0) return <Loader />;

  return (
    <Container maxWidth="lg" sx={{ marginTop: "86px" }}>
      <Box sx={{ marginBottom: 2 }}>
        <Breadcrumb
          breadcrumbs={breadcrumbItems}
          color="var(--black)"
          activeColor="var(--green)"
        />
      </Box>

      <Typography
        variant="h4"
        sx={{ fontWeight: 600, marginBottom: { xs: "12px", lg: "24px" } }}
      >
        Blogs
      </Typography>

      <BlogGrid blogResults={blogs.results} />
    </Container>
  );
};
