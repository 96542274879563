import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { BreadcrumbsProvider } from "contexts/breadcrumbContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BreadcrumbsProvider>
      <App />
    </BreadcrumbsProvider>
  </React.StrictMode>
);
reportWebVitals();
