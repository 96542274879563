import { Breadcrumbs, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import styles from "./styles.module.scss";
import { HTMLAttributes } from "react";
import { Link } from "react-router-dom";

type BreadcrumbItem = {
  label: string | null;
  link: string;
};

type Props = {
  breadcrumbs: BreadcrumbItem[];
  activeColor: string;
} & HTMLAttributes<HTMLDivElement>;

export const Breadcrumb = ({ breadcrumbs, activeColor, ...props }: Props) => {
  const mappedBreadcrumbs = breadcrumbs.map((item: any, index: number) => {
    switch (index) {
      case 0:
      default:
        return (
          <Link key={index + 1} to={item.link}>
            <Typography
              key={index + 1}
              className={styles.breadcrumb__text}
              color={props.color}
              sx={{
                ":hover": {
                  textDecorationColor: props.color,
                  textDecoration: "underline",
                },
              }}
            >
              {item.label}
            </Typography>
          </Link>
        );

      case breadcrumbs.length - 1:
        return (
          <Typography
            key={index + 1}
            className={styles.breadcrumb__text}
            style={{ color: activeColor }}
          >
            {item.label}
          </Typography>
        );
    }
  });

  return (
    <Breadcrumbs
      separator={
        <NavigateNext fontSize="small" style={{ fill: "var(--gray)" }} />
      }
    >
      {mappedBreadcrumbs}
    </Breadcrumbs>
  );
};
