import { BlogHero } from "pages/blogs/blog-inner/components/blog-hero";
import { useBlog } from "../../../hooks/useBlog";
import { HtmlParser } from "components/core/html-parser";
import { Container } from "@mui/material";
import { Blogs } from "components/sections/blogs";
import { Loader } from "components/core/loader";

export const BlogInnerPage = () => {
  const { blog } = useBlog();

  if (!blog) return <Loader />;
  return (
    <>
      {blog && blog.values && (
        <>
          {blog?.values.image && <BlogHero blog={blog} />}
          <Container maxWidth="lg" sx={{ paddingBlock: 6 }}>
            <HtmlParser htmlString={blog?.values.content} />
          </Container>

          <Blogs id="you-may-also-read" title="You May Also Read" slice={7} />
        </>
      )}
    </>
  );
};
