import { Box, Container, Grid, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { ReactComponent as About1 } from "assets/about-1.svg";
import { ReactComponent as About2 } from "assets/about-2.svg";

export const WhatIsOTL = (props: any) => {
  const aboutUsItems = [
    {
      image: <About1 />,
      text: "A logistics & trading company that is working on facilitating the trading process.",
    },
    {
      image: <About2 />,
      text: "OTL's main scope is to encourage the trading business & to be the leader of the trading business in Africa.",
    },
  ];
  return (
    <Box {...props}>
      <Container maxWidth="lg" className="section">
        <Typography variant="h3" className={styles.section__title}>
          What is OTL?
        </Typography>
        <Grid container spacing={{ xs: 1.5, md: 3 }}>
          {aboutUsItems.map((item, idx) => {
            return (
              <Grid
                item
                sm={12}
                md={6}
                key={"about-us-item-#" + idx}
                id={"about-us-item-#" + idx}
              >
                <Box className={styles.item}>
                  <Box className={styles.item__image}>{item.image}</Box>
                  <Typography variant="body1" className={styles.item__text}>
                    {item.text}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};
