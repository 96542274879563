export const Colors = {
  black: "#000000",
  midGray: "#939598",
  lightGray: "#DCDDDE",
  white: "#ffffff",
  babyBlue: "#37C1D2",
  green: "#03705A",
  lightGreen: "#11AB4B",
  orange: "#F36F25",
  lightOrange: "#FFC717",
  red: "#ED2928",
};
