import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HTMLAttributes } from "react";
import { BlogGrid } from "components/core/blog-grid";
import { useBlogs } from "hooks/useBlogs";
import { ReactComponent as Arrow } from "assets/Arrow.svg";
import styles from "./styles.module.scss";
import { Link, useParams } from "react-router-dom";
import { GetBlogsDto } from "dtos/get-blogs.dto";

type Props = {
  title: string;
  slice: number;
} & HTMLAttributes<HTMLDivElement>;

export const Blogs = ({ title, slice, ...props }: Props) => {
  const { id } = useParams();
  const { blogs } = useBlogs();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let blogsFinal: GetBlogsDto = { ...blogs };

  const getFinalBlogs = () => {
    if (id) {
      const filtered = blogs.results.filter((blog) => blog.id !== id);
      blogsFinal.results = filtered;
      blogsFinal.total = filtered.length;
    } else if (isSmallScreen) {
      const filtered = blogs.results.slice(0, 2);
      blogsFinal.results = filtered;
      blogsFinal.total = filtered.length;
    } else {
      const filtered = blogs.results.slice(0, slice);
      blogsFinal.results = filtered;
      blogsFinal.total = filtered.length;
    }
    return blogsFinal;
  };

  if (getFinalBlogs().total === 0) return <></>;
  
  return (
    <div {...props}>
      <Container maxWidth="lg" className="section">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: { xs: "12px", lg: "24px" },
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            {title}
          </Typography>

          <Link to="/blogs" className={styles.read__more}>
            <span>Read more</span>
            <Arrow />
          </Link>
        </Box>

        <BlogGrid blogResults={getFinalBlogs().results} />
      </Container>
    </div>
  );
};
