import { Container, Typography, useMediaQuery } from "@mui/material";
import { HighlightsCard } from "components/core/highlights-card";
import { useHighlights } from "hooks/useHighlights";
import Slider from "react-slick";
import { useTheme } from "@mui/material/styles";
import { NextArrow } from "components/core/slider/next-arrow";
import { PrevArrow } from "components/core/slider/prev-arrow";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/slider.scss";

export const Highlights = (props: any) => {
  const { highlights } = useHighlights();
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: onlySmallScreen
      ? 1
      : highlights?.total! < 3
      ? highlights?.total
      : 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  if (highlights?.results && highlights?.results.length === 0) return <></>;

  return (
    <>
      {highlights && (
        <Container maxWidth="lg" className="section" {...props}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, marginBottom: { xs: "12px", lg: "24px" } }}
          >
            Highlights
          </Typography>

          <Slider {...settings} className="base-slider">
            {highlights.results?.map((highlight) => {
              return (
                <HighlightsCard
                  key={`highlight-card-#${highlight.id}`}
                  highlight={highlight}
                />
              );
            })}
          </Slider>
        </Container>
      )}
    </>
  );
};
