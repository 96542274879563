import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { BlogCard } from "../blog-card";
import { BlogResult } from "dtos/get-blogs.dto";

type Props = {
  blogResults: BlogResult[];
};

export const BlogGrid = ({ blogResults }: Props) => {
  return (
    <Grid container spacing={{ xs: 1.5, md: 3 }}>
      {blogResults &&
        blogResults.map((blog) => {
          return (
            <Grid key={blog.id} item xs={6} md={4}>
              <Link to={`/blog/${blog.id}`}>
                <BlogCard blog={blog} />
              </Link>
            </Grid>
          );
        })}
    </Grid>
  );
};
