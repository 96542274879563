import { ReactComponent as ArrowRight } from "assets/arrow-left.svg";

export const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <ArrowRight />
    </div>
  );
};
