import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { BlogResult } from "dtos/get-blogs.dto";
import styles from "./styles.module.scss";
import moment from "moment";

type Props = {
  blog: BlogResult;
};

export const BlogCard = ({ blog }: Props) => {
  const publishedAt = moment(blog.values?.publish_date).format("MMMM DD, YYYY");

  return (
    <Card className="card" elevation={0}>
      <CardActionArea sx={{ padding: { xs: "0.5rem", lg: "1rem" } }}>
        <CardMedia
          id="card-media"
          component="img"
          image={blog.values?.image?.url}
          alt={blog.values?.image?.altText}
          sx={{ borderRadius: "4px", height: { xs: "120px", md: "240px" } }}
        />
        <CardContent
          sx={{
            padding: "unset",
            paddingTop: { xs: "0.5rem", lg: "1rem" },
            paddingBottom: { xs: "0.25rem", lg: "0.5rem" },
          }}
        >
          {blog.values?.tags &&
            blog.values?.tags.map((tag) => {
              return (
                <Chip
                  key={tag.id}
                  sx={{
                    marginBottom: { xs: "0.5rem", lg: "1rem" },
                    borderRadius: "6px",
                    marginInlineEnd: "6px",
                  }}
                  label={tag.label}
                  color="primary"
                  size="small"
                />
              );
            })}

          <Typography variant="h5" className={styles.card__title}>
            {blog.values?.title}
          </Typography>

          <Box className={styles.card__footer}>
            <Box className={styles.card__avatar__name}>
              <img
                id="author-image"
                src={blog.values?.author_image?.url}
                alt={blog.values?.author_image?.altText}
              />
              <Typography
                variant="body1"
                id="author"
                className={styles.card__footer__author}
              >
                {blog.values?.author}
              </Typography>
            </Box>
            <Typography
              variant="body1"
              id="published-at"
              className={styles.card__footer__publish__date}
            >
              {publishedAt}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
