import { GetBlogsDto } from "dtos/get-blogs.dto";
import { useEffect, useState } from "react";
import { getBlogs } from "services";

export const useBlogs = () => {
  const [blogs, setBlogs] = useState<GetBlogsDto>({
    total: 0,
    results: [],
  });

  useEffect(() => {
    Promise.all([getBlogs()]).then(([{ data: blogs }]) => {
      setBlogs(blogs);
    });
  }, []);

  return { blogs };
};
