import axios from "axios";
import { BlogResult, GetBlogsDto } from "dtos/get-blogs.dto";
import { GetHighlightsDto } from "dtos/get-highlights.dto";

export const orchestrator = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Hub-Db Controller
export const getBlogs = () => {
  return orchestrator.get<GetBlogsDto>("hub-spot/blogs");
};
export const getBlog = ({ rowId }: { rowId: string }) => {
  return orchestrator.get<BlogResult>(`hub-spot/blogById/${rowId}`);
};
export const getHighlights = () => {
  return orchestrator.get<GetHighlightsDto>("hub-spot/highlights");
};
