import { Grid, Container, Typography, Box } from "@mui/material";
import { ReactComponent as Linkedin } from "assets/linkedin.svg";
import { ReactComponent as Facebook } from "assets/facebook.svg";
import { ReactComponent as Instagram } from "assets/instagram.svg";
// import { ReactComponent as Whatsapp } from "assets/whatsapp.svg";
import styles from "./styles.module.scss";

export const Footer = () => {
  const linkItems = [
    {
      link: "https://www.facebook.com/otl.trade/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0",
      icon: <Facebook />,
    },
    {
      link: "https://www.instagram.com/otl.trade/",
      icon: <Instagram />,
    },
    {
      link: "https://www.linkedin.com/company/o-trade-logistics/",
      icon: <Linkedin />,
    },
    // {
    //   link: "https://wa.me/+201290022222",
    //   icon: <Whatsapp />,
    // },
  ];
  const year = new Date().getFullYear();

  return (
    <Container sx={{ py: 3, pt: 10 }}>
      <Grid container spacing={{ xs: 1.5, md: 3 }} justifyContent={"center"} marginBottom={3}>
        {linkItems.map((item, idx) => {
          return (
            <Grid item key={`link-item-#-${idx}`}>
              <a target="_blank" href={item.link} rel="noreferrer">
                <Box className={styles.logo__box}>{item.icon}</Box>
              </a>
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="body1" className={styles.footer__text}>
        Copyright © {year} O Trade & Logistics| All Rights Reserved
      </Typography>
    </Container>
  );
};
