import { Blogs } from "components/sections/blogs";
import { Otrovato } from "components/sections/otrovato";
import { Hero } from "components/sections/video-hero";
import { Highlights } from "components/sections/highlights";
// import { Testimonials } from "components/sections/testimonials";
import { WhatIsOTL } from "components/sections/whats-otl";

export const HomePage = () => {
  return (
    <>
      <Hero id="Home" />
      <WhatIsOTL id="What is OTL ?" />
      <Otrovato id="Otrovato" />
      <Blogs id="Blogs" title="Blogs" slice={3} />
      {/* <Testimonials id="Testimonials" /> */}
      <Highlights id="Highlights" />
    </>
  );
};
