import { GetHighlightsDto } from "dtos/get-highlights.dto";
import { useEffect, useState } from "react";
import { getHighlights } from "services";

export const useHighlights = () => {
  const [highlights, setHighlights] = useState<GetHighlightsDto>();

  useEffect(() => {
    Promise.all([getHighlights()]).then(([{ data: blogs }]) => {
      setHighlights(blogs);
    });
  }, []);

  return { highlights };
};
