import { BlogResult } from "dtos/get-blogs.dto";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getBlog } from "services";

export const useBlog = () => {
  const [blog, setBlog] = useState<BlogResult>();
  const { id } = useParams();

  useEffect(() => {
    id &&
      Promise.all([getBlog({ rowId: id })]).then(([{ data: blog }]) => {
        setBlog(blog);
      });
  }, [id]);

  return { blog };
};
