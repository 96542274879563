import { Box, Container, Typography } from "@mui/material";
import styles from "./styles.module.scss";
import { ReactComponent as OtrovatoLogo } from "assets/Otrovato-Logo.svg";

export const Otrovato = (props: any) => {
  return (
    <>
      <div className={styles.otrovato__background} />
      <Box className={`${styles.otrovato} section`} {...props}>
        <Container maxWidth="lg">
          <Box className={styles.title__container}>
            <Typography variant="h2">
              Trading at your fingertips with
              <a
                href="https://otrovato.com"
                target="_blank"
                rel="noreferrer"
                className={styles.otrovato__link}
              >
                <OtrovatoLogo className={styles.otrovato__logo} />
              </a>
            </Typography>
          </Box>
          <Typography variant="body1" className={styles.otrovato__description}>
            Otrovato made the trading process easy, smooth, ensuring hassle-free
            transactions and less risk Whether you are a buyer looking for
            products or a seller seeking new markets,
            {/* <a
              href="https://store.otl.trade"
              target="_blank"
              rel="noreferrer"
              className={styles.a__link}
            > */}
            &nbsp;Otrovato.com&nbsp;
            {/* </a> */}
            will facilitate your trading needs.
          </Typography>
        </Container>
      </Box>
    </>
  );
};
