import { Box, Card, CardContent, Typography } from "@mui/material";
import { HighlightResults } from "dtos/get-highlights.dto";
import { useState } from "react";
import styles from "./styles.module.scss";
import moment from "moment";

type Props = {
  highlight: HighlightResults;
};

export const HighlightsCard = ({ highlight }: Props) => {
  const [showMore, setShowMore] = useState(false);
  const publishDate = moment(highlight.values?.publish_date).format(
    "MMMM DD, YYYY"
  );
  if (!(highlight.values && highlight.values.text)) return <></>;
  return (
    <Card
      className="card"
      sx={{
        padding: { xs: "12px", lg: "1rem" },
        width: "100%",
      }}
      elevation={0}
    >
      <CardContent
        sx={{
          position: "relative",
          backgroundImage: `url(${highlight.values.image?.url})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: { xs: "470px", lg: "510px" },
          display: "flex",
          alignItems: "flex-end",
          borderRadius: "6px",
        }}
      >
        <div
          className={
            showMore
              ? styles.highlight__overlay__more
              : styles.highlight__overlay
          }
        />
        <div className={styles.highlight__text}>
          <Typography variant="body1">
            {showMore
              ? highlight.values?.text
              : `${highlight.values?.text.substring(0, 150)}`}
            {highlight.values?.text.length > 150 && (
              <Typography
                variant="body1"
                component={"span"}
                className={styles.highlight__more}
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? " Less" : " More"}
              </Typography>
            )}
          </Typography>
        </div>
      </CardContent>

      <Box className={styles.card__content}>
        <Typography variant="body2" className={styles.publish__date}>
          {publishDate}
        </Typography>
      </Box>
    </Card>
  );
};
